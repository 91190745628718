// KONTAKT

#kontakt {
//BACKGROUND MAP
background-image: url('../img/itdc-map.jpg');
background-size: cover;
background-position: center;
}

#kontaktdaten {

.adr--left {
position: relative;
padding-left: 3.3rem;
img {
position: absolute;
left: 0;
width: 40px;
height: 40px;
}
}
}