#footer, #kontaktdaten {
 position: relative;


.kont--wrap {
position: relative;
max-width: 75%;
min-width: 239px;
img {
width: 50px;
height: 50px;
padding: .6rem 1.3rem .6rem 0;
}

.kont--right {
position: absolute;
right: 0;
}
// .kont--wrap ENDE
}

ul {
  margin-left: 0;
  margin-top: 0;
  padding-left: 0;
  li {
  list-style-type: none;
  padding: 0 0 .6rem 0;
  }  

  li a {
  text-decoration: none;

  }
}




}/*ENDE #FOOTER*/






 section#footer button#btn-back-to-top 
 {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none;

  width: 45px;
  height: 45px;

  box-shadow: 0 0 2px $gray-400;
  border: 2px solid $white;
  background-color: $primary;

  color: $white;

  &:hover {
  box-shadow: 0 0 7px $gray-600;
  }
}

