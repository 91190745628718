*
    box-sizing: border-box
    margin: 0
    padding: 0

body,
html
    font-family: Arial, Helvetica, sans-serif
    color: $grayh1
    margin: 0
    padding: 0

h1, h2, h3, h4, h5, h6
    font-family: Segoe UI,Helvetica Neue,Arial,sans-serif
    color: $grayh1
    font-weight: bolder
    hyphens: auto

svg
    font-family: Segoe UI,Helvetica Neue,Arial,sans-serif

a
    color: $grayh1
    &:hover, 
    &:focus
        color: $secondary

h2.sml,
h2.sml.card-title 
    font-size: 1.5rem

h3, p.h3
    font-size: 2rem

p
    //text-align: justify
    hyphens: auto
    color:$gray80

li
    hyphens: auto
    color:$gray80


h2, p.h2, #leistungen p.h2
    color: $grayh1

#leistungen h2
    color: $grayh1


/// TOP LINE
.topline 
    color: $secondary
    font-size: 80%
    font-weight: bold

/// Separator ICON
.separator
    margin: 0
    border: 0
    &::before
        display: inline-block
        content: " "
        width:37px
        height:39px
        background-image: url('../img/icon-01.svg')
        background-size: cover
        background-repeat: no-repeat
        background-position: top center




// SUBPAGES COLORS // .ts => TEXT SECONDARY COLOR

.subpage
    h2,h3,h4,h5,h6,sup,.ts
        color: $secondary
    strong
        color: $gray80
    sup
        font-size: 60%

    s
        background-color: $blue1
        padding: .3em