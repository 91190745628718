//TEAM
#team {
 h3.card-title {font-size: 2.8em;color: $secondary;padding: 0;
}

  padding: 8em 0;

  .card {
    padding: 2em;
  }

  #armin .card-body,
  #frank .card-body {
    border: none;
    padding-top: 1em;

    ul li {
      margin-left: -20px;
      list-style-type: '-  ';
    }
  }


  ul li {
    margin-left: -33px;
    list-style-type: none;
  }

  .kontaktlinks {
    position: relative;
  }

  .kontaktlinks ul li {
    margin-left: -30px;
    list-style-type: none;
  }

  .kontaktlinks img {
    display: inline-block;
    padding: 0 1em .75em 0;
  }

  .kontaktlinks a {
    font-size: 1.5em;
    text-decoration: none;
    padding-bottom: .9em;
  }

}


@include media-breakpoint-down(md) {
#team .card {
position: relative;


  #armin .card-body, 
  #frank .card-body {
              border: none;
              padding:.5em 0;

              ul li {
              margin-left: -20px;list-style-type: '-  ';
              }       
            }
  }

.kontaktlinks img {display: inline-block; padding: 0 1em 0 0!important;width:50px;height:auto;}
.kontaktlinks a {display:inline-block;font-size: 1.2em!important;text-decoration: none;padding: 0;margin: 0;}


}
