//iphone
#header {
margin-top:85px;

/*.circle {
	top: -198px;
	right: calc(50% - 50vw);
	width: 40vw;
	height: 100vh;
	background: #3080e3;
	position: absolute;
	border-radius: 50%;
	z-index: -1;
  overflow: hidden;
}*/

.header-bg {
        background:url(../img/header-bg.svg)  bottom right / contain no-repeat;
        @include media-breakpoint-down(md) {
        background:url(../img/header-bg.svg)  top right / cover no-repeat;

        }

.slogan {
  min-height: 40vh;
  padding: 4em 1em 2em;
    h1 {
      color: $gray-900;
      font-size: 1.65em;
        span {
            color:$secondary;
              }
    }
  p {color: $gray-900;padding-top: 1em;}
  }


}
}



@include media-breakpoint-up(md) {
#header {

.header-bg {

.slogan {

    text-align: left;
    h1 {
      color: $black;
      font-size: 1.65em;
        span {
            color:$gray-900;
              }
    }

  p {color: $gray-700;padding-top: 1em;}

  }

}
}
}

@include media-breakpoint-up(lg) {
#header {

.header-bg {

.slogan {
    min-height: 70vh;
    text-align: left;
    h1 {
      font-size: 2.5em;
        span {
            color:$secondary;
              }
    }
  }

  .img-content {
   text-align: center;
  img {
      max-width: 60%;
      }
  }
}
}
}

@include media-breakpoint-up(xl) {
#header {

.header-bg {

.slogan {
    min-height: 80vh;
    text-align: left;
    h1 {
      font-size: 3em;
    }
  }

  
}
}
}