// ###############################################
// ###############################################
// START Boilerplate (not Important for the Tutorial)
    
$primary: #e83b3c;
$blue1: #1473e6;
$secondary: #392875;

$black: #000;
$white: #fff;
$grayh1: #222;
$gray1: #555;
$gray2: #fefefe;
$gray80: #3a3a3a;
$grayccc: #ccc;
$hover: $grayccc;
// END Boilerplate (not Important for the Tutorial)
// ###############################################
// ###############################################




.navbar-light .navbar-toggler,
.navbar-light .navbar-toggler:focus,
.navbar-light .navbar-toggler.collapsed {
    border: none;
    border-style: none;
    box-shadow: none;
}



// sections

.dots {
background:url(../img/dots.svg) top left  / contain no-repeat;
background-size: 110px 110px;
}




////////////////////////////// CTA1
#cta1 {
padding: 8em 1em;
background-color: $secondary;

    h3 {color:$white;}
    p {color: $white;}
    .button {margin-top:2em;}

/***** #cta1 ENDE ****/
        }
/***** #cta1 ENDE ****/