// BUTTONS
$border: 2px;
//$radius: ('5% / 20%');

a.button, .button {
display: inline-block;
margin-bottom: 1rem;
padding: .5rem 3rem;

border-radius: 7px / 7px;
border: $border solid $secondary;
background: $secondary;

color: $white;
font-size: 1.1rem;
font-weight: bold;
text-decoration: none!important;
text-align: center;

@include media-breakpoint-down(md) {
border: $border solid $white;
}
}

a.button.button-lg {
padding: 1em 5em;
font-size: 1.3rem;
}
@include media-breakpoint-down(md) {
a.button.button-lg {
width: 100%;
font-size: 1.1rem;
}
}

a.button.button-xs {
padding: .4rem 2rem;
font-size: .9rem;
}
@include media-breakpoint-down(md) {
a.button.button-xs {
width: 100%;
padding: .7rem 2rem;
font-size: 1rem;
}
}

a.button.button-white,
.button-white {
color: $secondary;
background: $white;
@include media-breakpoint-down(md) {
border: $border solid $secondary;
}
}


a.button:hover,
a.button:focus {
color: $grayh1;
background: $hover;
border: $border solid $hover;
}


