//LEISTUNGEN SCSS
#leistungen .card {
position: relative;
padding: 2em;

    h2.sml.card-title {
            padding: .7em 0 1.1em;

                }

    img {
    width: 80px;
    margin: 1em;
            }

   ul li {
    margin-left: -30px;list-style-type: '-  '
    }

}


@include media-breakpoint-down(md) {
#leistungen .card {
position: relative;
padding: 2em;


    h2.sml.card-title {
            padding: 1em 0 3em;
                }

    img {
    width: 80px;
    margin: 1em;
            }
}
}

