/* This changes all the animations globally */

$grid-breakpoints: (
  xs: 0,
  sm: 375px,
  md: 765px,
  lg: 1081px,
  xl: 1280px,
  xxl: 1400px
);


h2,  p.h2 {
    font-size: 2.5em;
    padding-bottom: .3em;
}


@include media-breakpoint-up(sm) {}
@include media-breakpoint-up(md) {}
@include media-breakpoint-up(lg) {}
@include media-breakpoint-up(xl) {
  h2,  p.h2 {
    font-size: 3em;
    padding-bottom: .3em;
}
}
@include media-breakpoint-up(xxl) {}

/***************************

@include media-breakpoint-up(sm) {

}
@include media-breakpoint-up(md) {

}
@include media-breakpoint-up(lg) {

}
@include media-breakpoint-up(xl) {

}
@include media-breakpoint-up(xxl) {

}

*****************************/