
$navbar-padding-y: $spacer * 1.3;
.navbar {
  padding-top: $navbar-padding-y;
  padding-bottom: $navbar-padding-y;
}



a.nav-link{ transition: all 1s ease;
    color: $grayh1!important;
    //font-weight:bold;
    border-bottom: 3px solid transparent;
    padding-top: 1.5em;  
    padding-bottom: 0;       
      @include media-breakpoint-up(md) {
               padding: .5em 1em;   
            }
}

a.nav-link.active{
      border-bottom: 3px solid transparent;
      color: $primary!important;
        @include media-breakpoint-up(md) {border-bottom: 3px solid $primary;
      }
}


// .navbar-toggler .navbar-toggler-icon {
// 	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(0, 0, 0, .8)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
// }


// .navbar-toggler.collapsed .navbar-toggler-icon {
// 	background-image: url("../img/cross.svg");
// }



